import React from "react";
import { Link } from "gatsby";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import "../styles/pages/_tank-you.scss";

const Error = () => (
  <Layout>
    <SEO title="Error page" />
    <section className="section-intro section-thank-you">
      <div className="container">
        <h1 className="title">DO-OHH</h1>
        <h3 className="subtitle">
          Looks like something went completely wrong!
        </h3>
        <p>
          But don't worry - it can happen to the best of us, -and it just
          happened to you.
        </p>
        <Link to="/">Return Home</Link>
      </div>
    </section>
  </Layout>
);

export default Error;
